<template>
  <a-form-model :model="registrasi_peserta_didik" ref="ruleForm" :rules="rules">
    <!-- <a-form-model-item :colon="false" prop="jurusan">
      <span slot="label">
        Jurusan&nbsp;
        <a-tooltip
          title="Jurusan yang dipilih oleh peserta didik saat diterima di sekolah ini (khusus SMK)."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        size="large"
        v-model="registrasi_peserta_didik.jurusan"
      ></a-input>
    </a-form-model-item> -->
    <a-form-model-item
      ref="jenis_pendaftaran"
      :colon="false"
      prop="jenis_pendaftaran"
    >
      <span slot="label">
        Jenis Pendaftaran&nbsp;
        <a-tooltip
          title="Status peserta didik saat pertama kali diterima di sekolah ini."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-select
        size="large"
        v-model="registrasi_peserta_didik.jenis_pendaftaran"
      >
        <a-select-option
          v-for="item in jenisPendaftaranList"
          :key="item.title"
          :value="item.title"
          >{{ item.title }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="nis">
      <span slot="label">
        NIS&nbsp;
        <a-tooltip
          title="Nomor induk peserta didik sesuai yang tercantum pada buku induk."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>

      <a-input v-model="registrasi_peserta_didik.nis" size="large"></a-input>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="tanggal_masuk_sekolah">
      <span slot="label">
        Tanggal Masuk Sekolah&nbsp;
        <a-tooltip
          title="Tanggal pertama kali peserta didik diterima di sekolah ini. Jika siswa baru, maka isikan tanggal awal tahun pelajaran saat peserta didik
masuk. Jika siswa mutasi/pindahan, maka isikan tanggal sesuai tanggal diterimanya peserta didik di sekolah ini atau tanggal yang
tercantum pada lembar mutasi masuk yang umumnya terdapat di bagian akhir buku rapor."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-date-picker
        v-model="registrasi_peserta_didik.tanggal_masuk_sekolah"
        size="large"
        placeholder=""
        class="w-100"
      />
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="provinsi_asal_sekolah">
      <span slot="label">
        Provinsi Asal Sekolah&nbsp;
        <a-tooltip
          title="Provinsi sekolah peserta didik sebelumnya. Untuk peserta didik baru, isikan provinsi sekolah pada jenjang sebelumnya. Sedangkan bagi
peserta didik mutasi/pindahan, diisi dengan provinsi sekolah sebelum pindah ke sekolah saat ini."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-select
        showSearch
        size="large"
        v-model="registrasi_peserta_didik.provinsi_asal_sekolah"
        @change="
          fetchLocationApi(
            'cities',
            registrasi_peserta_didik.provinsi_asal_sekolah
          )
        "
      >
        <a-select-option
          v-for="province in ppdb.provincesRegistration"
          :key="province.key"
          :value="province.key"
          >{{ province.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="kota_asal_sekolah">
      <span slot="label">
        Kota Asal Sekolah&nbsp;
        <a-tooltip
          title="Kota sekolah peserta didik sebelumnya. Untuk peserta didik baru, isikan kota sekolah pada jenjang sebelumnya. Sedangkan bagi
peserta didik mutasi/pindahan, diisi dengan kota sekolah sebelum pindah ke sekolah saat ini."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <!-- <a-input
        v-model="registrasi_peserta_didik.kota_asal_sekolah"
        size="large"
      ></a-input> -->
      <a-select
        :disabled="!registrasi_peserta_didik.provinsi_asal_sekolah"
        showSearch
        size="large"
        v-model="registrasi_peserta_didik.kota_asal_sekolah"
      >
        <a-select-option
          v-for="city in ppdb.citiesRegistration"
          :key="city.key"
          :value="city.name"
          >{{ city.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      ref="jenjang_asal_sekolah"
      :colon="false"
      prop="jenjang_asal_sekolah"
    >
      <span slot="label">
        Jenjang Asal Sekolah&nbsp;
        <a-tooltip
          title="Jenjang sekolah peserta didik sebelumnya. Untuk peserta didik baru, isikan jenjang sekolah pada jenjang sebelumnya. Sedangkan bagi
peserta didik mutasi/pindahan, diisi dengan jenjang sekolah sebelum pindah ke sekolah saat ini."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-select
        size="large"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        v-model="registrasi_peserta_didik.jenjang_asal_sekolah"
      >
        <a-select-option
          v-for="item in jenjangSekolahList"
          :key="item.title"
          :value="item.title"
          >{{ item.title }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      ref="status_asal_sekolah"
      :colon="false"
      prop="status_asal_sekolah"
    >
      <span slot="label">
        Status Asal Sekolah&nbsp;
        <a-tooltip
          title="status sekolah peserta didik sebelumnya. Untuk peserta didik baru, isikan status sekolah pada jenjang sebelumnya. Sedangkan bagi
peserta didik mutasi/pindahan, diisi dengan status sekolah sebelum pindah ke sekolah saat ini."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-select
        size="large"
        v-model="registrasi_peserta_didik.status_asal_sekolah"
      >
        <a-select-option
          v-for="item in statusSekolahList"
          :key="item.title"
          :value="item.title"
          >{{ item.title }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="asal_sekolah">
      <span slot="label">
        Nama Asal Sekolah&nbsp;
        <a-tooltip
          title="Nama sekolah peserta didik sebelumnya. Untuk peserta didik baru, isikan nama sekolah pada jenjang sebelumnya. Sedangkan bagi
peserta didik mutasi/pindahan, diisi dengan nama sekolah sebelum pindah ke sekolah saat ini."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        v-model="registrasi_peserta_didik.asal_sekolah"
        size="large"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="nomor_peserta_ujian">
      <span slot="label">
        Nomor Peserta Ujian&nbsp;
        <a-tooltip
          title="Nomor peserta ujian saat peserta didik masih di jenjang sebelumnya. Formatnya adalah x-xx-xx-xx-xxx-xxx-x (20 digit). Untuk Peserta
Didik WNA, diisi dengan Luar Negeri."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        v-model="registrasi_peserta_didik.nomor_peserta_ujian"
        size="large"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="nomor_seri_ijazah">
      <span slot="label">
        No. Seri Ijazah&nbsp;
        <a-tooltip
          title="Nomor seri ijazah peserta didik pada jenjang sebelumnya. Formatnya adalah 16 digit, diisi sesuai dengan ijazah jenjang sebelumnya."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        v-model="registrasi_peserta_didik.nomor_seri_ijazah"
        size="large"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item :colon="false">
      <span slot="label">
        Scan Ijazah Sekolah Jenjang Sebelumnya&nbsp;
        <a-tooltip
          title="Bukti Ijazah Sekolah Jenjang Sebelumnya (Tidak Wajib)"
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-upload
        :fileList="lampiran.ijazah"
        name="lampiran"
        :beforeUpload="file => uploadFile(file, 'ijazah')"
        :remove="file => handleRemove(file, 'ijazah')"
      >
        <a-button size="large" icon="upload">Unggah Gambar/Dokumen</a-button>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="nomor_seri_skhus">
      <span slot="label">
        No. Seri SKHUS&nbsp;
        <a-tooltip
          title="Nomor seri SKHUN/SHUN peserta didik pada jenjang sebelumnya (jika memiliki). Formatnya adalah 16 digit, diisi sesuai dengan ijazah jenjang sebelumnya."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input
        v-model="registrasi_peserta_didik.nomor_seri_skhus"
        size="large"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item :colon="false">
      <span slot="label">
        Scan Rapor Sekolah Jenjang Sebelumnya&nbsp;
        <a-tooltip
          title="Bukti Scan Rapor Sekolah Jenjang Sebelumnya (Tidak Wajib)"
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-upload
        :fileList="lampiran.rapor"
        name="lampiran"
        :beforeUpload="file => uploadFile(file, 'rapor')"
        :remove="file => handleRemove(file, 'rapor')"
      >
        <a-button size="large" icon="upload">Unggah Gambar/Dokumen</a-button>
      </a-upload>
    </a-form-model-item>
    <!-- <a-form-model-item :colon="false">
      <span slot="label">
        Lampiran Dokumen&nbsp;
        <a-tooltip
          title="Lampirkan 1 atau lebih dokumen yang mungkin dapat dilampirkan dan dibutuhkan oleh sekolah."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-upload-dragger
        accept="application/pdf"
        multiple
        :fileList="lampiran"
        name="lampiran"
        :beforeUpload="uploadFile"
        :remove="handleRemove"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          Tekan komponen atau letakkan dokumen untuk mengunggah dokumen baru.
        </p>
        <p class="ant-upload-hint">
          Dapat mengunggah 1 atau lebih dokumen sekaligus. Hanya menerima PDF dan maksimum 8mb.
        </p>
      </a-upload-dragger>
    </a-form-model-item> -->
    <header-menu
      title="Pendaftaran Keluar"
      subtitle="Diisi jika peserta didik sudah keluar"
    />
    <a-form-model-item ref="keluar_karena" :colon="false" prop="keluar_karena">
      <span slot="label">
        Keluar Karena&nbsp;
        <a-tooltip
          title="Alasan utama peserta didik keluar dari sekolah. Pilih Lulus apabila peserta didik telah lulus dari seklolah, pilih Mengundurkan diri apabila peserta didik
keluar sekolah karena mengundurkan diri dengan catatan (dibuktikan adanya surat pengunduran diri), pilih Putus sekolah apabila peserta didik
meninggalkan sekolah tanpa keterangan yang jelas."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-select size="large" v-model="registrasi_peserta_didik.keluar_karena">
        <a-select-option
          v-for="item in keluarKarenaList"
          :key="item.title"
          :value="item.title"
          >{{ item.title }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="tanggal_keluar">
      <span slot="label">
        Tanggal Keluar&nbsp;
        <a-tooltip
          title="Tanggal saat peserta didik diketahui/tercatat keluar dari sekolah."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-date-picker
        v-model="registrasi_peserta_didik.tanggal_keluar"
        size="large"
        placeholder=""
        class="w-100"
      />
    </a-form-model-item>
    <a-form-model-item :colon="false" prop="alasan_keluar">
      <span slot="label">
        Alasan&nbsp;
        <a-tooltip
          title="Alasan khusus yang melatarbelakangi peserta didik keluar dari sekolah."
        >
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-textarea
        v-model="registrasi_peserta_didik.alasan_keluar"
        :auto-size="{ minRows: 4, maxRows: 5 }"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { jenisPendaftaranList, jenjangSekolahList, statusSekolahList, keluarKarenaList } from '@/helpers/listsPpdbSelect'
import { mapState } from 'vuex'
const HeaderMenu = () => import('@/components/app/Registration/Header')
const rules = {
  jenis_pendaftaran: [
    { required: true, message: 'Jenis Pendaftaran wajib diisi!', trigger: 'change' },
  ],
  nis: [
    { required: true, message: 'NIS wajib diisi!', trigger: 'change' },
  ],
  tanggal_masuk_sekolah: [
    { required: true, message: 'Tanggal Masuk Sekolah wajib diisi!', trigger: 'change' },
  ],
  asal_sekolah: [
    { required: true, message: 'Asal Sekolah wajib diisi!', trigger: 'change' },
  ],
  provinsi_asal_sekolah: [
    { required: true, message: 'Provinsi Asal Sekolah wajib diisi!', trigger: 'change' },
  ],
  kota_asal_sekolah: [
    { required: true, message: 'Kota Asal Sekolah wajib diisi!', trigger: 'change' },
  ],
  jenjang_asal_sekolah: [
    { required: true, message: 'Jenjang Asal Sekolah wajib diisi!', trigger: 'change' },
  ],
  status_asal_sekolah: [
    { required: true, message: 'Status Asal Sekolah wajib diisi!', trigger: 'change' },
  ],
  nomor_peserta_ujian: [
    { required: true, message: 'Nomor Peserta Ujian wajib diisi!', trigger: 'change' },
    { len: 20, message: 'Nomor Peserta Ujian harus 20 huruf!', trigger: 'change' },
  ],
  nomor_seri_ijazah: [
    { required: true, message: 'Nomor Seri Ijazah wajib diisi!', trigger: 'change' },
    { len: 16, message: 'Nomor Seri Ijazah harus 16 huruf!', trigger: 'change' },
  ],
  nomor_seri_skhus: [
    { len: 16, message: 'Nomor Seri SKHUS harus 16 huruf!', trigger: 'change' },
  ],
}
export default {
  name: 'FormRegistrasiPesertaDidik',
  components: {
    HeaderMenu,
  },
  data() {
    return {
      jenisPendaftaranList,
      jenjangSekolahList,
      statusSekolahList,
      keluarKarenaList,
      registrasi_peserta_didik: {
        jurusan: null,
        jenis_pendaftaran: null,
        nis: null,
        tanggal_masuk_sekolah: null,
        asal_sekolah: null,
        provinsi_asal_sekolah: null,
        kota_asal_sekolah: null,
        jenjang_asal_sekolah: null,
        status_asal_sekolah: null,
        nomor_peserta_ujian: null,
        nomor_seri_ijazah: null,
        nomor_seri_skhus: null,
        keluar_karena: null,
        tanggal_keluar: null,
        alasan_keluar: null,
      },
      rules,
    }
  },
  props: ['isClickAction', 'data', 'lampiran'],
  async mounted() {
    this.registrasi_peserta_didik = {
      ...this.registrasi_peserta_didik,
      ...this.data,
    }
    const { provincesRegistration } = this.ppdb
    if (!provincesRegistration.length) await this.fetchLocationApi()
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    async fetchLocationApi(region = 'provinces', model) {
      try {
        const regionAction = region.toUpperCase()
        const payload = { current: 3 }
        if (model) payload.id = model.split('_')[0]
        await this.$store.dispatch(`ppdb/FETCH_${regionAction}`, payload)
        // this.locationLists[region] = data.map(dat => {
        //   return {
        //     key: dat.id + '_' + dat.nama,
        //     name: dat.nama,
        //   }
        // })

        this.registrasi_peserta_didik.kota_asal_sekolah = null
        // console.log(this.locationLists[region])
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    uploadFile(file, type) {
      this.$emit('addLampiran', { file, type })
      return false
    },
    handleRemove(file, type) {
      this.$emit('removeLampiran', { file, type })
    },
  },
  watch: {
    'isClickAction.next'(newVal) {
      if (newVal) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit('actionValidate', { action: 'next', data: { registrasi_peserta_didik: this.registrasi_peserta_didik } })
            // this.registrasi_peserta_didik = {
            //   jurusan: null,
            //   jenis_pendaftaran: null,
            //   nis: null,
            //   tanggal_masuk_sekolah: null,
            //   asal_sekolah: null,
            //   kota_asal_sekolah: null,
            //   jenjang_asal_sekolah: null,
            //   status_asal_sekolah: null,
            //   nomor_peserta_ujian: null,
            //   nomor_seri_ijazah: null,
            //   nomor_seri_skhus: null,
            //   keluar_karena: null,
            //   tanggal_keluar: null,
            //   alasan_keluar: null,
            // }
          } else {
            this.$emit('actionValidate', false)
            this.$notification.error({
              message: 'Maaf',
              description: 'Ada data wajib yang belum terisi.',
            })
            return false
          }
        })
      }
    },
    'isClickAction.previous'(newVal) {
      if (newVal) {
        console.log('masuk prev')
        this.$refs.ruleForm.validate(valid => {
          // if (valid) {
          this.$emit('actionValidate', { action: 'previous', data: { registrasi_peserta_didik: this.registrasi_peserta_didik } })
          // } else {
          //   this.$emit('actionValidate', false)
          //   return false
          // }
        })
      }
    },
  },
  computed: {
    ...mapState(['ppdb']),
  },
}
</script>

<style>
</style>
